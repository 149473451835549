#root {
  overflow: hidden;
}

.app > div {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row > * {
  margin: 5px;
}

.cardMainContainer {
  h1 {
    font-family: "Damion", cursive;
    color: #fff;
    text-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.3);
  }

  h2 {
    color: #fff;
  }
}

.swipe {
  position: absolute;
}

.cardContainer {
  user-select: none;
  max-width: 360px;
  height: 300px;
  margin: auto;
  position: relative;
}

.card {
  position: absolute;
  background-color: #fff;
  width: 360px;
  left: 50%;
  transform: translateY(-50);
  max-width: 360px;
  height: 300px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: #fff;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buttons button {
  flex-shrink: 0;
  padding: 10px;
  margin: 24px;
  width: 64px;
  height: 64px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 18px;
  background-color: #9198e5;
  transition: 200ms;
  font-weight: bolder;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}

.buttons button:hover {
  transform: scale(1.05);
}

@media (max-width: 625px) {
  .buttons button {
    margin: 16px;
  }
}

@keyframes popup {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(1.1, 1.1);
  }
  30% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1, 1);
  }
  57% {
    transform: scale(1, 1);
  }
  64% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.card {
  color: #ecf0f1;
  border-radius: 0.8rem;
}

.face {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  transition: transform 0.5s ease-in-out;
  backface-visibility: hidden;
  color: black;
  background: white;
}

.face-back {
  transform: rotateY(180deg);
}

.face-front {
  transform: rotateY(-180deg);
}
