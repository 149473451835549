.sticky-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  color: white;
  transition: 0.3s ease-in;
  height: 48px;
}

.primary {
  box-shadow: 1px 1px 5px 5px rgba(17, 17, 17, 0.01);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 16%, transparent);
}

.secondary {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 16%, transparent);
  box-shadow: none;
}

.sticky-header::before {
  content: "";
  height: 48px;
  width: 100%;
  position: absolute;
  z-index: -10;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(20, 20, 20);
  opacity: 0;
  transition: all 0.6s ease-in;
}

.sticky-header.nav-menu-visible::before {
  transition: 0.3s ease-in;
  opacity: 1;
}

.netflix-logo {
  font-size: 2rem;
  font-weight: bold;
}

.main-nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.main-nav ul li {
  cursor: pointer;
}

.main-nav ul li:hover {
  text-decoration: underline;
}

.user-menu {
  cursor: pointer;
  margin-right: 10px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  .nav-menu {
    position: absolute;
    top: 45px;
    right: 30px;
    background-color: #111;
    list-style: none;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    li {
      cursor: pointer;
      padding: 3px 5px;

      i {
        width: 18px;
        height: 18px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.searchInputContainer .close-search-button {
  right: 4px;
}

.searchInputContainer .fa-search {
  font-size: 12px;
  pointer-events: none;
  top: 6px;
}

.searchInputContainer input {
  width: 35vw;
  max-width: 200px;
  padding-left: 40px;
  outline: none;
  background: transparent;
  border: 1px solid var(--color-white);
  border-radius: 16px;
  opacity: 0.5;
}

.searchInputContainer input:focus {
  opacity: 1;
  box-sizing: border;
  border: 2px solid var(--color-primary);
}

.fa-times {
  padding: 6px 8px;
  border-radius: 16px;
  &:hover {
    background: rgba(100, 100, 100, 0.3);
  }
}

.search-button {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 1000;
}

.search-container {
  padding-top: 100px;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.932);
  color: white;
  justify-content: center;
  align-items: center;
  transition: top 0.5s;
  z-index: 9999;
  overflow-y: scroll;
}

.search-container p {
  color: rgb(255, 255, 248);
}

.search-container.show {
  top: 48px;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

/* Webkit browsers (Chrome, Safari) */
.search-container::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
}

.search-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Color of the scrollbar thumb */
}

.search-container::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

.hide_link {
  display: none;
}

@media (max-width: 767px) {
  .hide_in_mobile {
    display: none;
  }

  .show_in_mobile {
    display: block !important;
  }
}
