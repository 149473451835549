@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

#video_player {
  height: 50% !important;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  --color-primary: rgba(255, 60, 0, 0.95);
  --color-secondary: #ffa75e;
  --color-white: #fff6ff;
  --color-indigo: rgb(144 138 243);
}

.bg-indigo {
  background: rgb(144 138 243);
}

.color-primary {
  color: var(--color-primary)
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  font-size: initial;
  font-weight: bold;
  font-family: "Arial Black";
}

h4 {
  color: var(--color-primary);
}

button {
  filter: brightness(0.99);
}

img {
  user-select: none;
}

button:hover {
  filter: brightness(1.05);
}

button:active {
  transform: scale(0.90);
}

* {
  -webkit-tap-highlight-color: transparent;
}

button {
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  overscroll-behavior-x: none;
}

* {
  box-sizing: border-box;
}

video {
  outline: none;
  background: black;
}

form button:hover {
  opacity: 0.8;
}

p {
  white-space: break-spaces;
}

/* Remove autofill styles START */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}
/* Remove autofill styles END */

/* ICONS START */
.icon-fullscreen-alt:before {
  content: "\e000";
}
.icon-fullscreen-out:before {
  content: "U+25A1";
}
.icon-fullscreen-exit-alt:before {
  content: "\e001";
}
.icon-play:before {
  content: "\e002";
}
.icon-pause:before {
  content: "\e003";
}
.icon-volume:before {
  content: "\e004";
}
.icon-volume-2:before {
  content: "\e005";
}
.icon-square-corners:before {
  content: "U+26F6";
}

.bg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
  background: rgba(50, 50, 50, 0.9);

  &:hover {
    opacity: 0.95;
  }

  &:active {
    opacity: 0.9;
    filter: brightness(1.1);
  }
}

/* ICONS END */

/* SHARED CLASSES */
.page-container {
  min-height: 100dvh;
  display: block;
}
.section {
  display: block;
  padding-top: 24px;
  padding-bottom: 40px;
}
.section-container {
  margin: auto;
  padding-left: 8px;
  padding-right: 8px;
}

@media screen and (min-width: 800px) {
  .section {
    padding-top: 64px;
    padding-bottom: 80px;
  }
  .section-container {
    width: 700px;
  }
}

@media screen and (min-width: 1100px) {
  .section-container {
    width: 1024px;
  }
}

@media screen and (min-width: 1500px) {
  .section-container {
    width: 1400px;
  }
}

.color-primary {
  color: var(--color-primary);
}

.bg-primary {
  background-color: #fff6ff;
}

.bg-secondary {
  background: linear-gradient(#f48e4e, #f98787);
}

.bg-secondary-2 {
  background: linear-gradient(#f1eee3, #ecede9);
}

.bg-video {
  background: linear-gradient(#171717, #242424);
}

.bg-green {
  background: linear-gradient(#dff5df, #f6feea);
}

.bg-yellow {
  background: linear-gradient(#f5e9df, #fae4b0);
}

.paper {
  background: white;
  box-shadow: 1px 1px 5px 5px rgba(0, 0, 0, 0.5),
    1px 1px 2px 2px rgba(0, 0, 0, 0.2);
}

.text-primary {
  color: #f48e4e;
}

.text-primary-helper {
  color: #f98787;
}

.text-gray {
  color: #7d7875;
}

.text-secondary {
  color: #fdfdfd;
}

.h1-primary {
  color: #f48e4e;
}

.text-yellowishorange {
  color: var(--color-secondary);
}

.text-dark-yellowishorange {
  color: #fce3cd;
  font-size: 2em;
  text-shadow: 0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black,
    0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black,
    0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black,
    0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black,
    0px 0px 1.5px black, 0px 0px 1.5px black, 0px 0px 1.5px black;
  /* text-shadow: 0px 0px 2px black, 0px 0px 4px black; */
}

.h1-secondary {
  color: #fdfdfd;
}

/* SHARED CLASSES END */
/* CSS to enforce landscape orientation */
/* TODO: Improve mobile rotation landscape experience */
/* @media screen and (orientation: landscape) {
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    font-size: 2em;
    text-align: center;
  }

  body::before {
    content: "Please rotate your device to landscape mode.";
  }

  #app {
    display: none;
  }
} */