
.MainContainer {
    background: #222;
    position: fixed !important;
    height: 100%;
    /* transform: translateX(calc(-50%)); */
    /* position: absolute !important; */
    /* left: 50%; */
}

.ShortVideo {
    flex: 1;
    height: 100%;
}

div[data-vjs-player="true"], div[data-vjs-player="true"] > div {
    height: 100%;
}

div[data-vjs-player="true"] > div > video-js {
    padding-top: 0 !important;
    overflow-y: hidden;
    border-radius: 2px;
    box-sizing: border-box;

    @media (max-width: 700px) {
        border-radius: 0;
    }
}

/** VIDEO CONTROLS */

.video-js .vjs-picture-in-picture-control {
    display: none;
}

.video-js .vjs-control-bar {
    background-color: transparent;
}

.video-js .vjs-fullscreen-control, .video-js .vjs-progress-control, .video-js .vjs-volume-panel, .video-js .vjs-play-control {
    color: rgba(255, 60, 0, 0.95);
}

.video-js .vjs-play-progress {
    background-color: rgba(255, 60, 0, 0.95);
}

.vjs-error .vjs-error-display .vjs-modal-dialog-content {
    padding-top: 160px;
}

.video-js .vjs-big-play-button {
    display: none;
}

.video-js .vjs-modal-dialog {
    overflow: hidden;
}

/***/

.MainContainer {
    margin: 0 auto;
    position: relative;
    width: 100%;
    overflow: hidden;

    /* @media (max-width: 600px) {
        width: 100vw;
    } */
}

.VideoContainer {
    position: relative;
    width: 500px;
    margin: auto;
    height: 100vh;
    height: 100dvh;
    margin-bottom: 1vh;
    padding-top: 1vh;
    background: black;
    @media (max-width: 600px) {
        width: 100vw;
        border-radius: 0;
        margin-bottom: 0;
        padding-top: 0;
    }
}

.MainContainer .BarsSmall {
    position: 'absolute';
}

.QuizMain video {
    /* object-fit: cover; */
}

.video-js {
    width: 100% !important;
    height: 100% !important;
  }
  

.QuizMain h1 {
    font-size: 3em;
}

.QuizMain .Subtitles {
    position: absolute;
    top: 60vh;
    width: 100vw;
    text-shadow: '0 0 2px black, 0 0 2px black';
    text-align: center;
    text-shadow: 1px 0px black, 1px 0px black, 1px 0px black;
}

.Empty-videoFiller {
    margin-top: 160px;
    height: 50vh;
    width: 100vw;
    background: black;
}

.PlayButton {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: large;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: absolute;
    top: 40vh;
    left: 50%;
    transform: translate(-50%) scale(1);
    z-index: 10;
    transition: none;
}

.animatePause {
    transform: translate(-50%) scale(2);
    transition: 0.725s ease;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0) !important;
}

.scrollsub-container {
    height: 120px;
    min-width: 100px;
    width: 50%;
    scroll-snap-type: y mandatory;
    color: white;
    box-shadow: 1px 1px 5px 5px #0000001f;
    background: #0000001f;

    @media (max-width: 600px) {
        /* min-width: 300px; */
        width: 70%;
    }
}

.scroll-list {
    scroll-snap-type: x mandatory;
    padding: 0 40px;
    
    @media (max-width: 600px) {
        padding: 0 10px;
    }
}

.scroll-list__item {
    scroll-snap-align: center;
    padding-top: 15px;
    white-space: nowrap;
}

.scroll-list-container__right-bar {
    /* padding: 4px 10px; */
    /* background: linear-gradient(-90deg, black, black, black, transparent) */
    background-color: #000;
}

/* @media (min-width: 468px) { */
    /* Webkit browsers (Chrome, Safari) */
    .scroll-list::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  
    .scroll-list::-webkit-scrollbar-thumb {
      background-color: transparent; /* Color of the scrollbar thumb */
    }
  
    .scroll-list::-webkit-scrollbar-track {
      background-color: transparent; /* Color of the scrollbar track */
    }
  /* } */
  

/* RelatedVideos */

.RelatedVideosBackdrop {
    position: absolute;
    height: 100vh;
    height: 100dvh;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    color: aliceblue;
    z-index: 40;
}

.RelatedVideosMenu {
    margin: 0 4px;
    width: calc(100% - 8px);
    background: rgb(244, 242, 250);
    border-radius: 16px 16px 0 0;
    position: absolute;
    bottom: 0;
    height: 80dvh;

    * {
        color: #fff;
    }

    h4 {
        color: var(--color-primary);
        font-size: large;
    }
}
