
.MyListMain {
    background: rgb(255, 145, 0);
    flex-grow: 1;
}

.TagListItems {
    box-shadow: inset 1px 1px 5px 1px rgba(0, 0, 0, 0.05), 1px 1px 5px 1px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-height: 84px;
    border-radius: 4px;
}

.TagItem {
    background: #f4feee;
    padding: 2px 4px;
    margin: 2px;
    font-weight: bolder;
    border-radius: 2px;
    color: rgb(65 181 0 / 86%);
}

.TagItem::after {
    content: "";
    position: absolute;
    width: 0;
    left: 0;
    top: 0;
    height: 100%;
    background-color: rgb(153 255 0 / 56%);
}

.TagItem.repeat1::after {
    width: 14%;
}

.TagItem.repeat2::after {
    width: 28%;
}

.TagItem.repeat3::after {
    width: 42%;
}

.TagItem.repeat4::after {
    width: 56%;
}

.TagItem.repeat5::after {
    width: 70%;
}

.TagItem.repeat6::after {
    width: 84%;
}

.TagItem.repeat7::after {
    width: 100%;
}


.TagListButtons {
    display: flex;
}

.TagItem.selected {
    border: 1px solid black;
    /* box-shadow: -1px -1px black; */
}
