.language_modal {
  width: 60%;
  height: 80vh;
  left: 36%;
  top: 41px;
  user-select: none;
}

@media (max-width: 600px) {
  .language_modal {
    width: 100vw;
    height: 100vh;
    left: 0%;
    position: 'absolute';
    top: 0;
  }
}
