
.videoItem {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  object-fit: contain;
  background: black;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
}

.videoItem video {
  background: #333;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  max-height: 60vw;
}
.videoItem .controls {
  position: absolute;
  bottom: 0;
  background: transparent;
  opacity: 0;
  padding: 24px 0;
  transition: ease 0.125s;
}
.videoItem .controls:hover {
  opacity: 1;
}
.videoItem .controls {
  width: 100%;
  padding-bottom: 24px;
  bottom: 0;
}
.videoItem.fullScreen {
  width: 100vw;
  margin: auto;
}
.videoItem.fullScreen video {
  width: 100%;
  height: auto;
  max-height: initial;
}

.timeLeft {
  color: #fff;
  font-size: small;
  float: right;
}
.slider {
  width: 100%;
}
.slider {
  -webkit-appearance: none;
  height: 4px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}
.slider:hover {
  height: 6px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* appearance: none; */
  width: 12px;
  height: 12px;
  background: #f48e4e;
  cursor: pointer;
  border-radius: 12px;
}
.slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #f48e4e;
  cursor: pointer;
  border-radius: 12px;
}
.controls .row1 {
  padding: 8px;
}
.volumeInfo {
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  color: aliceblue;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 16px 24px;
  opacity: 0.8;
  border-radius: 8px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  user-select: none;
}
.hidden {
  visibility: hidden;
}

.volume {
  width: 90px;
  height: 3px;
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.radio-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.radio-container .checkmark {
  display: none;
  color: silver;
  margin-left: 8px;
  font-size: 10px;
}

.radio-container input:checked + p + .checkmark {
  display: inline;
}

.cursor-pointer {
  cursor: pointer;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .rotateVideo {
    z-index: 1000;
    position: fixed !important;
    width: 100vh !important;
    height: 100% !important;
    left: -8% !important;
    min-width: fit-content !important;
    object-fit: cover !important; /* Ensure the video covers the entire viewport */
    transform-origin: center center; /* Center of rotation */
    transform: rotate(90deg) !important; /* Rotate the video 90 degrees */
  }

  .rotateVideo video {
    top: 75%;
  }
}

@media (max-width: 600px) {
  .subtitle {
    font-size: 3vw;
  }
  .videoItem {
    height: 40vh;
  }

  .subtitle-dropdown {
    left: -200px;
  }
  .play-pouse-btn {
    top: -20px;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  color: #fff;
  padding: 20px;
  border-radius: 50%;
}

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.card-section {
  overflow: hidden;
}


