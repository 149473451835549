.account_page {
  p,
  h1,
  span {
    color: silver;
  }
}

.account_page {
  padding-top: 12rem;

  .info span,
  .info i {
    font-size: 12rm;
  }
  
  .info p {
    font-size: 14rm;
  }
}


@media (max-width: 600px) {
  .account_page {
    .info {
      margin: 5px;
      overflow: hidden;
    }
  
    .info i {
      font-size: 14px;
    }
  }
}
